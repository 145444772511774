import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { environment } from '../../../environments/handyhand/environment.dk';
import { createActivity, ActivityModel, ActivityType } from '@app/models/activity.model';
import { SponsoredBanner } from '@app/components/seoPageElements/sponsored-banners/sponsored-banners.component';

type ValidType =
  | 'task_title'
  | 'task_description'
  | 'offer_message'
  | 'offer_price_increase_message'
  | 'offer_comment'
  | 'task_comment'
  | 'review_message'
  | 'user_description'
  | 'user_education';

@Injectable({ providedIn: 'root' })
export class MiscService {
  baseHref = `${environment.apiUrl}/misc`;
  dashboardHref = environment.dashboardBaseRef;

  constructor(private http: HttpClient) {}

  getLastActivities(): Observable<ActivityModel[]> {
    const url = `${this.baseHref}/last-activities`;
    return this.http.get<ActivityModel[]>(url).pipe(
      map((activities) => activities.map(activity => createActivity(activity))),
    );
  }

  getInsurance(countryCode: string): Observable<any> {
    const url = `${this.dashboardHref}api/other/insurance/${countryCode}`;
    return this.http.get(url);
  }

  getPrivacyPolicy(): Observable<any> {
    const url = `${this.dashboardHref}api/other/cookies`;
    return this.http.get(url);
  }

  getTerms(): Observable<any> {
    const url = `${this.dashboardHref}api/other/terms`;
    return this.http.get(url);
  }

  getSponsoredBanners(
    citySlug: string,
    verticalSlug: string,
  ): Observable<SponsoredBanner[]> {
    const url = `${this.dashboardHref}api/sponsored-banners`;
    return this.http.get<SponsoredBanner[]>(url, {
      params: { citySlug, verticalSlug },
    });
  }
}
