export interface ActivityModel {
  id?: string;
  type: ActivityType;
  date: Date;
  profileImg?: string;
  fullname?: string;
  taskname?: string;
  city?: string;
  trackingId?: string;
}

export function createActivity(data: Partial<ActivityModel>): ActivityModel {
  return {
    ...data,
    date: data.date ? new Date(data.date) : new Date(),
    type: data.type || ActivityType.NEW_USER,
  };
}

export enum ActivityType {
  NEW_USER = 'NEW_USER',
  NEW_HH = 'NEW_HH',
  NEW_TASK = 'task-created',
  NEW_BID = 'offer-created',
  TASK_COMPLETED = 'task-completed',
  TASK_ACCEPTED = 'offer-accepted',
  HH_UPDATED = 'user-updated',
  NEW_REVIEW = 'review-created', // 5 stars only
  HH_VISITED = 'HH_VISITED',
  NEW_OFFER_COM = 'offer-comment-created',
  NEW_TASK_COM = 'task-comment-created',
  NEW_PAYMENT = 'NEW_PAYMENT',
}
